/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderLineItemModifierResultModel from './OrderLineItemModifierResultModel';

/**
* The OrderLineItemResultModel model module.
* @module model/OrderLineItemResultModel
* @version 1.0.0
*/
export default class OrderLineItemResultModel {
    /**
    * Constructs a new <code>OrderLineItemResultModel</code>.
    * @alias module:model/OrderLineItemResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>OrderLineItemResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OrderLineItemResultModel} obj Optional instance to populate.
    * @return {module:model/OrderLineItemResultModel} The populated <code>OrderLineItemResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderLineItemResultModel();
                        
            
            if (data.hasOwnProperty('Uid')) {
                obj['Uid'] = ApiClient.convertToType(data['Uid'], 'String');
            }
            if (data.hasOwnProperty('ItemName')) {
                obj['ItemName'] = ApiClient.convertToType(data['ItemName'], 'String');
            }
            if (data.hasOwnProperty('VariationName')) {
                obj['VariationName'] = ApiClient.convertToType(data['VariationName'], 'String');
            }
            if (data.hasOwnProperty('ImageUrl')) {
                obj['ImageUrl'] = ApiClient.convertToType(data['ImageUrl'], 'String');
            }
            if (data.hasOwnProperty('Description')) {
                obj['Description'] = ApiClient.convertToType(data['Description'], 'String');
            }
            if (data.hasOwnProperty('Note')) {
                obj['Note'] = ApiClient.convertToType(data['Note'], 'String');
            }
            if (data.hasOwnProperty('ItemId')) {
                obj['ItemId'] = ApiClient.convertToType(data['ItemId'], 'String');
            }
            if (data.hasOwnProperty('VariationId')) {
                obj['VariationId'] = ApiClient.convertToType(data['VariationId'], 'String');
            }
            if (data.hasOwnProperty('Quantity')) {
                obj['Quantity'] = ApiClient.convertToType(data['Quantity'], 'Number');
            }
            if (data.hasOwnProperty('BasePrice')) {
                obj['BasePrice'] = ApiClient.convertToType(data['BasePrice'], 'Number');
            }
            if (data.hasOwnProperty('GrossSales')) {
                obj['GrossSales'] = ApiClient.convertToType(data['GrossSales'], 'Number');
            }
            if (data.hasOwnProperty('TotalTax')) {
                obj['TotalTax'] = ApiClient.convertToType(data['TotalTax'], 'Number');
            }
            if (data.hasOwnProperty('TotalDiscount')) {
                obj['TotalDiscount'] = ApiClient.convertToType(data['TotalDiscount'], 'Number');
            }
            if (data.hasOwnProperty('Total')) {
                obj['Total'] = ApiClient.convertToType(data['Total'], 'Number');
            }
            if (data.hasOwnProperty('Modifiers')) {
                obj['Modifiers'] = ApiClient.convertToType(data['Modifiers'], [OrderLineItemModifierResultModel]);
            }
        }
        return obj;
    }

    /**
    * @member {String} Uid
    */
    'Uid' = undefined;
    /**
    * @member {String} ItemName
    */
    'ItemName' = undefined;
    /**
    * @member {String} VariationName
    */
    'VariationName' = undefined;
    /**
    * @member {String} ImageUrl
    */
    'ImageUrl' = undefined;
    /**
    * @member {String} Description
    */
    'Description' = undefined;
    /**
    * @member {String} Note
    */
    'Note' = undefined;
    /**
    * @member {String} ItemId
    */
    'ItemId' = undefined;
    /**
    * @member {String} VariationId
    */
    'VariationId' = undefined;
    /**
    * @member {Number} Quantity
    */
    'Quantity' = undefined;
    /**
    * @member {Number} BasePrice
    */
    'BasePrice' = undefined;
    /**
    * @member {Number} GrossSales
    */
    'GrossSales' = undefined;
    /**
    * @member {Number} TotalTax
    */
    'TotalTax' = undefined;
    /**
    * @member {Number} TotalDiscount
    */
    'TotalDiscount' = undefined;
    /**
    * @member {Number} Total
    */
    'Total' = undefined;
    /**
    * @member {Array.<module:model/OrderLineItemModifierResultModel>} Modifiers
    */
    'Modifiers' = undefined;




}
