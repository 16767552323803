import { default as CustomStorage } from './customStorage';
import { ApiClient, AccountApi, CatalogApi, LoyaltyApi, CardApi, OrderApi, FavoriteApi, DeliveryApi, PresenceApi, InventoryApi } from './poppin_pay_api';
import { default as ConfigService } from './configService';
import { default as Internationalization } from './i18n';
import moment from 'moment';

export default class ApiService {
    constructor() {
        this.config = ConfigService.get();

        if (this.config.ApiUrlBasePath) {
            ApiClient.instance.basePath = this.config.ApiUrlBasePath;
        }

        this.account = new AccountApi();
        this.catalog = new CatalogApi();
        this.loyalty = new LoyaltyApi();
        this.card = new CardApi();
        this.order = new OrderApi();
        this.favorite = new FavoriteApi();
        this.delivery = new DeliveryApi();
        this.presence = new PresenceApi();
        this.inventory = new InventoryApi();

        this._profile = {
            id: null,
            accessToken: null,
            refreshToken: null,
            expires: null
        }
    }

    __initialize(callback) {
        callback(this, ApiClient.instance);
    }

    initialize(callback) {
        CustomStorage.get('storedApiProfile') 
            .then((parsedResponse) => { 
                if (parsedResponse) {
                    this._profile = parsedResponse;
                    if (this._profile && this._profile.id)
                        CustomStorage.setPrivateKey(this._profile.id);
                }
                callback();
            });        
    }

    _storeProfile() {
        CustomStorage.set('storedApiProfile', this._profile);
    }

    _getLoginStatus() {
        if (!this._profile.accessToken || !this._profile.expires || !this._profile.id)
            return false;
        
        var expires = moment(this._profile.expires);
        var now = moment();

        if (now.isBefore(expires))
            return true;

        return false;
    }

    isLoggedIn() {
        var loginStatus = this._getLoginStatus();
        return loginStatus && ApiClient.instance.authentications['oauth2'].accessToken != null;
    }

    tryAutoLogin(callback) {
        var loginStatus = this._getLoginStatus();
        if (loginStatus == true) {
            ApiClient.instance.authentications['oauth2'].accessToken = this._profile.accessToken;
            callback(null, true, null);
            return;
        }

        if (!this._profile.refreshToken) {
            callback(null, false, null);
            return;            
        }

        this.refreshToken((error, data, response) => {
            var finalStatus = this._getLoginStatus();
            callback(null, finalStatus, null);
        });
    }

    logout() {
        this._profile = {
            id: null,
            accessToken: null,
            refreshToken: null,
            expires: null
        };

        CustomStorage.setPrivateKey(null);
        ApiClient.instance.authentications['oauth2'].accessToken = null;

        this._storeProfile();
    }

    getAddCardUrl(locationId, countryCode) {
        return ApiClient.instance.buildUrl('Home/CaptureCard?clientId={clientId}&locationId={locationId}&language={language}&country={country}', {
            clientId: this.config.ClientId,
            locationId: locationId,
            language: Internationalization.getCurrentLanguageCode(),
            country: countryCode || 'US'
        });
    }

    getTermsOfUseUrl() {
        return ApiClient.instance.buildUrl('Home/TermsOfUse?clientId={clientId}', {
            clientId: this.config.ClientId
        });
    }

    cardCreate(model, callback) {
        model.clientId = this.config.ClientId;
        this.card.cardCreate(model, this.createLogErrorCallbackHandler(model, 'cardCreate', callback));
    }
    
    cardList(model, callback) {
        model.clientId = this.config.ClientId;
        this.card.cardList(model, this.createLogErrorCallbackHandler(model, 'cardList', callback));        
    }

    cardMerge(model, callback) {
        model.clientId = this.config.ClientId;
        this.card.cardMerge(model, this.createLogErrorCallbackHandler(model, 'cardMerge', callback));        
    }    

    cardRemove(model, callback) {
        model.clientId = this.config.ClientId;
        this.card.cardRemove(model, this.createLogErrorCallbackHandler(model, 'cardRemove', callback));        
    }

    orderPlace(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderPlace(model, this.createLogErrorCallbackHandler(model, 'orderPlace', callback));
    }

    orderGuestPlace(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderGuestPlace(model, this.createLogErrorCallbackHandler(model, 'orderGuestPlace', callback));
    }

    orderGuestQueue(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderGuestQueue(model, this.createLogErrorCallbackHandler(model, 'orderGuestQueue', callback));
    }        

    orderQueue(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderQueue(model, this.createLogErrorCallbackHandler(model, 'orderQueue', callback));
    }

    orderOrderStatus(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderOrderStatus(model, this.createLogErrorCallbackHandler(model, 'orderOrderStatus', callback));        
    }

    orderGuestOrderStatus(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderGuestOrderStatus(model, this.createLogErrorCallbackHandler(model, 'orderGuestOrderStatus', callback));                
    }    

    orderList(model, callback) {
        model.clientId = this.config.ClientId;
        this.order.orderList(model, this.createLogErrorCallbackHandler(model, 'ordersList', callback));
    }

    inventoryList(model, callback) {
        model.clientId = this.config.ClientId;        
        this.inventory.inventoryList(model, this.createLogErrorCallbackHandler(model, 'inventoryList', callback));
    }

    getProfile(callback) {
        var model = {
            clientId: this.config.ClientId
        }
        this.account.accountProfile(model, this.createLogErrorCallbackHandler(model, 'getProfile', callback));
    }

    getAuthProfile(callback) {
        callback(null, this._profile, null);
    }    

    accountStatus(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountStatus(model, this.createLogErrorCallbackHandler(model, 'accountStatus', callback));
    }

    accountUpdate(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountUpdate(model, this.createLogErrorCallbackHandler(model, 'accountUpdate', (error, data, response) => {
            callback(error, data, response);
        }));
    }

    _handleLoginResult(error, data, response) {
        if (error) {
            this._profile.id = null;
            this._profile.accessToken = null;
            this._profile.refreshToken = null;
            this._profile.expires = null;
        } else if (data) {
            this._profile.id = data.user_id;
            this._profile.accessToken = data.access_token;
            this._profile.refreshToken = data.refresh_token;
            this._profile.expires = moment(response.body['.expires']).toISOString();
        }

        CustomStorage.setPrivateKey(this._profile.id);

        this._storeProfile();

        var accessToken = data && data.access_token ? data.access_token : null;

        ApiClient.instance.authentications['oauth2'].accessToken = accessToken;
    }

    refreshToken(callback) {
        var model = {
            ClientId: this.config.ClientId,
            GrantType: 'refresh_token',
            RefreshToken: this._profile.refreshToken
        };

        this.account.accountLogin(model, (error, data, response) => {
            this._handleLoginResult(error, data, response);
            this.logErrorCallback(model, 'refreshToken', error, data, response);
            callback(error, data, response);            
        })
    }

    login(model, callback) {
        model.ClientId = this.config.ClientId;
        model.GrantType = 'password';

        this.account.accountLogin(model, (error, data, response) => {
            this._handleLoginResult(error, data, response);
            this.logErrorCallback(model, 'login', error, data, response);
            callback(error, data, response);
        });
    }

    forgotPassword(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountForgotPassword(model, this.createLogErrorCallbackHandler(model, 'forgotPassword', callback));
    }

    resetPassword(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountResetPassword(model, this.createLogErrorCallbackHandler(model, 'resetPassword', callback));
    }

    accountRegister(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountRegister(model, this.createLogErrorCallbackHandler(model, 'accountRegister', callback));
    }

    accountVerify(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountVerify(model, this.createLogErrorCallbackHandler(model, 'accountVerify', callback));
    }

    accountResendRegisterCode(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountResendRegisterCode(model, this.createLogErrorCallbackHandler(model, 'accountResendRegisterCode', callback));        
    }

    accountSendLoginCode(model, callback) {
        model.clientId = this.config.ClientId;
        this.account.accountSendLoginCode(model, this.createLogErrorCallbackHandler(model, 'accountSendLoginCode', callback));        
    }

    catalogIndex(model, callback) {
        model.clientId = this.config.ClientId;
        this.catalog.catalogIndex(model, this.createLogErrorCallbackHandler(model, 'catalogIndex', callback));
    }

    loyaltyEnroll(model, callback) {
        model.clientId = this.config.ClientId;
        this.loyalty.loyaltyEnroll(model, this.createLogErrorCallbackHandler(model, 'loyaltyEnroll', callback));
    }

    loyaltyResendEnrollCode(model, callback) {
        model.clientId = this.config.ClientId;
        this.loyalty.loyaltyResendEnrollCode(model, this.createLogErrorCallbackHandler(model, 'loyaltyResendEnrollCode', callback));
    }

    loyaltyVerify(model, callback) {
        model.clientId = this.config.ClientId;
        this.loyalty.loyaltyVerify(model, this.createLogErrorCallbackHandler(model, 'loyaltyVerify', callback));
    }

    loyaltyStatus(model, callback) {
        model.clientId = this.config.ClientId;
        if (this.isLoggedIn()) {
            this.loyalty.loyaltyStatus(model, this.createLogErrorCallbackHandler(model, 'loyaltyStatus', callback));
        } else {
            this.loyalty.loyaltyCheckAvailability(model, this.createLogErrorCallbackHandler(model, 'loyaltyCheckAvailability', callback));
        }
    }

    favoriteAdd(model, callback) {
        model.clientId = this.config.ClientId;
        this.favorite.favoriteCreate(model, this.createLogErrorCallbackHandler(model, 'favoriteAdd', callback));
    }

    favoriteMerge(model, callback) {
        model.clientId = this.config.ClientId;
        this.favorite.favoriteMerge(model, this.createLogErrorCallbackHandler(model, 'favoriteMerge', callback));
    }    

    favoriteList(callback) {
        var model = {
            clientId: this.config.ClientId
        };
        this.favorite.favoriteList(model, this.createLogErrorCallbackHandler(model, 'favoriteList', callback));
    }

    favoriteRemove(model, callback) {
        model.clientId = this.config.ClientId;
        this.favorite.favoriteRemove(model, this.createLogErrorCallbackHandler(model, 'favoriteRemove', callback));
    }

    deliveryAddressList(model, callback) {
        model.clientId = this.config.ClientId;
        this.delivery.deliveryListAddresses(model, this.createLogErrorCallbackHandler(model, 'deliveryAddressList', callback));
    }

    deliveryAddressValidate(model, callback) {
        model.clientId = this.config.ClientId;
        this.delivery.deliveryValidateAddress(model, this.createLogErrorCallbackHandler(model, 'deliveryAddressValidate', callback));
    }

    deliveryAddressCreate(model, callback) {
        model.clientId = this.config.ClientId;
        this.delivery.deliveryCreateAddress(model, this.createLogErrorCallbackHandler(model, 'deliveryAddressCreate', callback));
    }

    deliveryAddressUpdate(model, callback) {
        model.clientId = this.config.ClientId;
        this.delivery.deliveryUpdateAddress(model, this.createLogErrorCallbackHandler(model, 'deliveryAddressUpdate', callback));
    }

    deliveryAddressDelete(model, callback) {
        model.clientId = this.config.ClientId;
        this.delivery.deliveryDeleteAddress(model, this.createLogErrorCallbackHandler(model, 'deliveryAddressDelete', callback));
    }    

    presenceImHere(model, callback) {
        model.clientId = this.config.ClientId;
        this.presence.presenceImHere(model, this.createLogErrorCallbackHandler(model, 'presenceImHere', callback));        
    }

    createLogErrorCallbackHandler(model, call, callback) {
        return (error, data, response) => {
            this.logErrorCallback(model, call, error, data, response);
            callback(error, data,response);
        }
    }

    logErrorCallback(model, call, error, data, response) {
        console.log('Call: ', call);
        console.log('Model: ', model);
        console.log('Data: ', data);
        console.log('Response: ', response);        

         if (error) {
            var extra = {
                data: data,
                response: response,
                model: model,
                route: call,
                body: (response == null ? null : response.body),
                error: error
            };

            var fingerprint = [ "{{ default }}", this.config.ClientId, call ];

            global.sentry.captureMessage("Error In Method: " + call, { extra: extra, fingerprint: fingerprint });
        } else {
            global.sentry.captureBreadcrumb({
                category: 'api.model.' + call,
                message: JSON.stringify(model),
                level: "debug",
            });

            global.sentry.captureBreadcrumb({
                category: 'api.data.' + call,
                message: JSON.stringify(data),
                level: "debug",
            });
            
            global.sentry.captureBreadcrumb({
                category: 'api.body.' + call,
                message: JSON.stringify((response == null ? null : response.body)),
                level: "debug",
            });            
        }
    }
}

ApiService.instance = new ApiService();