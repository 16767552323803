/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Variation model module.
* @module model/Variation
* @version 1.0.0
*/
export default class Variation {
    /**
    * Constructs a new <code>Variation</code>.
    * @alias module:model/Variation
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Variation</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Variation} obj Optional instance to populate.
    * @return {module:model/Variation} The populated <code>Variation</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Variation();
                        
            
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('Price')) {
                obj['Price'] = ApiClient.convertToType(data['Price'], 'Number');
            }
            if (data.hasOwnProperty('Ordinal')) {
                obj['Ordinal'] = ApiClient.convertToType(data['Ordinal'], 'Number');
            }
            if (data.hasOwnProperty('TrackInventory')) {
                obj['TrackInventory'] = ApiClient.convertToType(data['TrackInventory'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    'Id' = undefined;
    /**
    * @member {String} Name
    */
    'Name' = undefined;
    /**
    * @member {Number} Price
    */
    'Price' = undefined;
    /**
    * @member {Number} Ordinal
    */
    'Ordinal' = undefined;
    /**
    * @member {Boolean} TrackInventory
    */
    'TrackInventory' = undefined;




}
