/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Modifier from './Modifier';
import SelectionType from './SelectionType';

/**
* The ModifierList model module.
* @module model/ModifierList
* @version 1.0.0
*/
export default class ModifierList {
    /**
    * Constructs a new <code>ModifierList</code>.
    * @alias module:model/ModifierList
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ModifierList</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ModifierList} obj Optional instance to populate.
    * @return {module:model/ModifierList} The populated <code>ModifierList</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ModifierList();
                        
            
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('SelectionType')) {
                obj['SelectionType'] = SelectionType.constructFromObject(data['SelectionType']);
            }
            if (data.hasOwnProperty('Ordinal')) {
                obj['Ordinal'] = ApiClient.convertToType(data['Ordinal'], 'Number');
            }
            if (data.hasOwnProperty('Modifiers')) {
                obj['Modifiers'] = ApiClient.convertToType(data['Modifiers'], [Modifier]);
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    'Id' = undefined;
    /**
    * @member {String} Name
    */
    'Name' = undefined;
    /**
    * @member {module:model/SelectionType} SelectionType
    */
    'SelectionType' = undefined;
    /**
    * @member {Number} Ordinal
    */
    'Ordinal' = undefined;
    /**
    * @member {Array.<module:model/Modifier>} Modifiers
    */
    'Modifiers' = undefined;




}
