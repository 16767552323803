/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The LoginResultBindingModel model module.
* @module model/LoginResultBindingModel
* @version 1.0.0
*/
export default class LoginResultBindingModel {
    /**
    * Constructs a new <code>LoginResultBindingModel</code>.
    * @alias module:model/LoginResultBindingModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LoginResultBindingModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoginResultBindingModel} obj Optional instance to populate.
    * @return {module:model/LoginResultBindingModel} The populated <code>LoginResultBindingModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoginResultBindingModel();
                        
            
            if (data.hasOwnProperty('client_id')) {
                obj['client_id'] = ApiClient.convertToType(data['client_id'], 'String');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'String');
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('access_token')) {
                obj['access_token'] = ApiClient.convertToType(data['access_token'], 'String');
            }
            if (data.hasOwnProperty('refresh_token')) {
                obj['refresh_token'] = ApiClient.convertToType(data['refresh_token'], 'String');
            }
            if (data.hasOwnProperty('.expires')) {
                obj['.expires'] = ApiClient.convertToType(data['.expires'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} client_id
    */
    'client_id' = undefined;
    /**
    * @member {String} user_id
    */
    'user_id' = undefined;
    /**
    * @member {String} firstName
    */
    'firstName' = undefined;
    /**
    * @member {String} lastName
    */
    'lastName' = undefined;
    /**
    * @member {String} userName
    */
    'userName' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {String} phoneNumber
    */
    'phoneNumber' = undefined;
    /**
    * @member {String} access_token
    */
    'access_token' = undefined;
    /**
    * @member {String} refresh_token
    */
    'refresh_token' = undefined;
    /**
    * @member {String} .expires
    */
    '.expires' = undefined;




}
