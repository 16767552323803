/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import InventoryListBindingModel from '../model/InventoryListBindingModel';
import InventoryListResultModel from '../model/InventoryListResultModel';
import ModelStateError from '../model/ModelStateError';

/**
* Inventory service.
* @module api/InventoryApi
* @version 1.0.0
*/
export default class InventoryApi {

    /**
    * Constructs a new InventoryApi. 
    * @alias module:api/InventoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the inventoryList operation.
     * @callback module:api/InventoryApi~inventoryListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InventoryListResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/InventoryApi~inventoryListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InventoryListResultModel}
     */
    inventoryList(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InventoryListResultModel;

      return this.apiClient.callApi(
        '/api/Inventory/List', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
