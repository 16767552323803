type VariationId = string;

type InventoryCount = {
    count: number
}

type CatalogVariation = {
    TrackInventory: boolean
}

type VariationInventoryMap = {[key: string]: InventoryCount};
type VariationCatalogMap = {[key: string]: CatalogVariation};

interface CartEntry {
    variation: VariationId
    quantity: number
}

interface Variation {
    Id: VariationId
}

interface Item {
    Variations: Variation[]
}

interface InventorySummary {
    stockCount: number
    remaining: number
    inCart: number
}

export class InventoryHelper {
    public static isItemAllOutOfStock(item:Item, variationsById: VariationCatalogMap, inventoryMap: VariationInventoryMap, cartEntries: CartEntry[]): boolean {
        for(var i=0;i<item.Variations.length;i++) {
            const variation = item.Variations[i];
            const summary = InventoryHelper.getInventorySummary(variation.Id, variationsById, inventoryMap, cartEntries);
            if (summary.remaining > 0) {
                return false;
            }
        }
        return true;
    }

    public static getInventorySummary(variationId: VariationId, variationsById: VariationCatalogMap, inventoryMap: VariationInventoryMap, cartEntries: CartEntry[], currentEntry: CartEntry|null = null) : InventorySummary {
        let inCart = 0;
        if (cartEntries && cartEntries.length) {
            for(var i=0;i<cartEntries.length;i++) {
                var cartEntry = cartEntries[i];
                if (cartEntry.variation !== variationId) {
                    continue;
                }

                inCart += cartEntry.quantity;
            }
        }

        let inStock = Number.MAX_SAFE_INTEGER;

        var catalogVariation = variationsById[variationId];
        if (catalogVariation && catalogVariation.TrackInventory === true)
        {
            const inventory = inventoryMap[variationId];
            if (inventory) {
                inStock = inventory.count;
            }
        }
        
        var currentEntryQuantity = 0;
        if (currentEntry) {
            currentEntryQuantity = currentEntry.quantity;
        }
        const remaining = inStock - inCart + currentEntryQuantity;

        const summary = {
            stockCount: inStock,
            remaining: remaining,
            inCart: inCart
        };

        return summary;
    }
    
}