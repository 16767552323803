import * as signalR from '@microsoft/signalr';
import { default as ConfigService } from './configService';
import { default as Store } from './store';
import { default as ApiService } from './apiService';

export default class InventoryHubService {
    constructor() {
        var config = ConfigService.get();
        if (!config.MessagingUrlBasePath) {
            return;
        }

        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(config.MessagingUrlBasePath + "/inventory", { 
                logMessageContent: true,
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .configureLogging(signalR.LogLevel.None)
            .build();

        this._connection.onclose((error) => {
            this.onConnectionClosed(error);
        });

        this._connection.on('InventoryUpdated', (event) => {
            var store = Store.instance.getStore();
            store.dispatch({ type: 'INVENTORY_UPDATED', locationId: event.locationId, variationId: event.variationId, count: event.count });
        });
    }

    locationChanged(locationId, callback) {
        ApiService.instance.inventoryList({locationId: locationId}, (inventoryError, inventoryData, inventoryResponse) => {
            if (inventoryError) {
                var extra = {
                    error: inventoryError,
                    locationId: locationId
                }
                global.sentry.captureMessage("Failed to get latest inventory for location", { extra: extra });
            }

            if (inventoryData && inventoryData.Inventory && inventoryData.Inventory.length > 0) {

                var action = {
                    type: 'INVENTORY_BULK_UPDATED',
                    entries: []
                };

                for(var i=0;i<inventoryData.Inventory.length;i++) {
                    var entry = inventoryData.Inventory[i];
                    action.entries.push({locationId: locationId, variationId: entry.VariationId, count: entry.Count});
                }
                
                var store = Store.instance.getStore();
                store.dispatch(action);
            }
            callback();
        });
    }
    
    onConnectionClosed(error) {
        // reconnect after 4-8 seconds
        // note start method has a built in exponential backoff
        setTimeout(() => {
            this.start((connected, error) => {
                if (error) {
                    var extra = {
                        error: error
                    };
                    global.sentry.captureMessage("Failed to reconnect to InventoryHub", { extra: extra });
                }
            }, 4, 500);
        }, this.getRandomInterval(4000, 8000));
    }

    start(callback, maxAttempts, delay) {
        maxAttempts = maxAttempts || 2;
        delay = delay || 500;

        var config = ConfigService.get();
        if (!config.MessagingUrlBasePath) {
            return;
        }

        this.exponentialBackoff(() => this._connection.start(), maxAttempts, delay, (result, error) => {
            if (error) {
                var extra = {
                    maxAttempts: maxAttempts,
                    delay: delay
                };
                global.sentry.captureMessage("Failed to connect to InventoryHub", { extra: extra });
                callback(null, error);
                return;
            }

            this._connection.invoke('Subscribe', config.ClientId)
                .then(() => {
                    callback(true, null);
                })
                .catch((reason) => {
                    callback(null, reason);
                });
        });
    }

    getRandomInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    exponentialBackoff(promiseToTry, max, delay, callback) {
        promiseToTry()
            .then((result) => {
                callback(result, null);
            })
            .catch((reason) => {
                if (max > 0) {
                    setTimeout(() => {
                        this.exponentialBackoff(promiseToTry, --max, delay * 2, callback);
                    }, delay);                
                } else {
                    callback(null, reason);
                }
            });
    }

    stop(callback) {
        var config = ConfigService.get();
        if (!config.MessagingUrlBasePath) {
            return;
        }

        this._connection.stop().finally(callback);
    }
}

InventoryHubService.instance = new InventoryHubService();