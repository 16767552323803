/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The LocationHours model module.
* @module model/LocationHours
* @version 1.0.0
*/
export default class LocationHours {
    /**
    * Constructs a new <code>LocationHours</code>.
    * @alias module:model/LocationHours
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LocationHours</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LocationHours} obj Optional instance to populate.
    * @return {module:model/LocationHours} The populated <code>LocationHours</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LocationHours();
                        
            
            if (data.hasOwnProperty('DayOfWeek')) {
                obj['DayOfWeek'] = ApiClient.convertToType(data['DayOfWeek'], 'String');
            }
            if (data.hasOwnProperty('Opens')) {
                obj['Opens'] = ApiClient.convertToType(data['Opens'], 'String');
            }
            if (data.hasOwnProperty('Closes')) {
                obj['Closes'] = ApiClient.convertToType(data['Closes'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} DayOfWeek
    */
    'DayOfWeek' = undefined;
    /**
    * @member {String} Opens
    */
    'Opens' = undefined;
    /**
    * @member {String} Closes
    */
    'Closes' = undefined;




}
