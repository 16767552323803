/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The DeliveryAddressValidationResult model module.
* @module model/DeliveryAddressValidationResult
* @version 1.0.0
*/
export default class DeliveryAddressValidationResult {
    /**
    * Constructs a new <code>DeliveryAddressValidationResult</code>.
    * @alias module:model/DeliveryAddressValidationResult
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>DeliveryAddressValidationResult</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DeliveryAddressValidationResult} obj Optional instance to populate.
    * @return {module:model/DeliveryAddressValidationResult} The populated <code>DeliveryAddressValidationResult</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeliveryAddressValidationResult();
                        
            
            if (data.hasOwnProperty('LocationId')) {
                obj['LocationId'] = ApiClient.convertToType(data['LocationId'], 'String');
            }
            if (data.hasOwnProperty('Available')) {
                obj['Available'] = ApiClient.convertToType(data['Available'], 'Boolean');
            }
            if (data.hasOwnProperty('Distance')) {
                obj['Distance'] = ApiClient.convertToType(data['Distance'], 'Number');
            }
            if (data.hasOwnProperty('Score')) {
                obj['Score'] = ApiClient.convertToType(data['Score'], 'Number');
            }
            if (data.hasOwnProperty('SourceLatitude')) {
                obj['SourceLatitude'] = ApiClient.convertToType(data['SourceLatitude'], 'Number');
            }
            if (data.hasOwnProperty('SourceLongitude')) {
                obj['SourceLongitude'] = ApiClient.convertToType(data['SourceLongitude'], 'Number');
            }
            if (data.hasOwnProperty('DestinationLatitude')) {
                obj['DestinationLatitude'] = ApiClient.convertToType(data['DestinationLatitude'], 'Number');
            }
            if (data.hasOwnProperty('DestinationLongitude')) {
                obj['DestinationLongitude'] = ApiClient.convertToType(data['DestinationLongitude'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} LocationId
    */
    'LocationId' = undefined;
    /**
    * @member {Boolean} Available
    */
    'Available' = undefined;
    /**
    * @member {Number} Distance
    */
    'Distance' = undefined;
    /**
    * @member {Number} Score
    */
    'Score' = undefined;
    /**
    * @member {Number} SourceLatitude
    */
    'SourceLatitude' = undefined;
    /**
    * @member {Number} SourceLongitude
    */
    'SourceLongitude' = undefined;
    /**
    * @member {Number} DestinationLatitude
    */
    'DestinationLatitude' = undefined;
    /**
    * @member {Number} DestinationLongitude
    */
    'DestinationLongitude' = undefined;




}
