/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The DeliveryAddressModel model module.
* @module model/DeliveryAddressModel
* @version 1.0.0
*/
export default class DeliveryAddressModel {
    /**
    * Constructs a new <code>DeliveryAddressModel</code>.
    * @alias module:model/DeliveryAddressModel
    * @class
    * @param AddressLine1 {String} 
    * @param Locality {String} 
    * @param AdministrativeDistrictLevel1 {String} 
    * @param PostalCode {String} 
    */

    constructor(AddressLine1, Locality, AdministrativeDistrictLevel1, PostalCode) {
        
        
        this['AddressLine1'] = AddressLine1;
        this['Locality'] = Locality;
        this['AdministrativeDistrictLevel1'] = AdministrativeDistrictLevel1;
        this['PostalCode'] = PostalCode;
        
    }

    /**
    * Constructs a <code>DeliveryAddressModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DeliveryAddressModel} obj Optional instance to populate.
    * @return {module:model/DeliveryAddressModel} The populated <code>DeliveryAddressModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeliveryAddressModel();
                        
            
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('AddressLine3')) {
                obj['AddressLine3'] = ApiClient.convertToType(data['AddressLine3'], 'String');
            }
            if (data.hasOwnProperty('Locality')) {
                obj['Locality'] = ApiClient.convertToType(data['Locality'], 'String');
            }
            if (data.hasOwnProperty('Sublocality1')) {
                obj['Sublocality1'] = ApiClient.convertToType(data['Sublocality1'], 'String');
            }
            if (data.hasOwnProperty('Sublocality2')) {
                obj['Sublocality2'] = ApiClient.convertToType(data['Sublocality2'], 'String');
            }
            if (data.hasOwnProperty('Sublocality3')) {
                obj['Sublocality3'] = ApiClient.convertToType(data['Sublocality3'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel1')) {
                obj['AdministrativeDistrictLevel1'] = ApiClient.convertToType(data['AdministrativeDistrictLevel1'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel2')) {
                obj['AdministrativeDistrictLevel2'] = ApiClient.convertToType(data['AdministrativeDistrictLevel2'], 'String');
            }
            if (data.hasOwnProperty('AdministrativeDistrictLevel3')) {
                obj['AdministrativeDistrictLevel3'] = ApiClient.convertToType(data['AdministrativeDistrictLevel3'], 'String');
            }
            if (data.hasOwnProperty('PostalCode')) {
                obj['PostalCode'] = ApiClient.convertToType(data['PostalCode'], 'String');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'String');
            }
            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('Organization')) {
                obj['Organization'] = ApiClient.convertToType(data['Organization'], 'String');
            }
            if (data.hasOwnProperty('PhoneNumber')) {
                obj['PhoneNumber'] = ApiClient.convertToType(data['PhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('NickName')) {
                obj['NickName'] = ApiClient.convertToType(data['NickName'], 'String');
            }
            if (data.hasOwnProperty('Instructions')) {
                obj['Instructions'] = ApiClient.convertToType(data['Instructions'], 'String');
            }
            if (data.hasOwnProperty('Latitude')) {
                obj['Latitude'] = ApiClient.convertToType(data['Latitude'], 'Number');
            }
            if (data.hasOwnProperty('Longitude')) {
                obj['Longitude'] = ApiClient.convertToType(data['Longitude'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    'Id' = undefined;
    /**
    * @member {String} AddressLine1
    */
    'AddressLine1' = undefined;
    /**
    * @member {String} AddressLine2
    */
    'AddressLine2' = undefined;
    /**
    * @member {String} AddressLine3
    */
    'AddressLine3' = undefined;
    /**
    * @member {String} Locality
    */
    'Locality' = undefined;
    /**
    * @member {String} Sublocality1
    */
    'Sublocality1' = undefined;
    /**
    * @member {String} Sublocality2
    */
    'Sublocality2' = undefined;
    /**
    * @member {String} Sublocality3
    */
    'Sublocality3' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel1
    */
    'AdministrativeDistrictLevel1' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel2
    */
    'AdministrativeDistrictLevel2' = undefined;
    /**
    * @member {String} AdministrativeDistrictLevel3
    */
    'AdministrativeDistrictLevel3' = undefined;
    /**
    * @member {String} PostalCode
    */
    'PostalCode' = undefined;
    /**
    * @member {String} Country
    */
    'Country' = undefined;
    /**
    * @member {String} FirstName
    */
    'FirstName' = undefined;
    /**
    * @member {String} LastName
    */
    'LastName' = undefined;
    /**
    * @member {String} Organization
    */
    'Organization' = undefined;
    /**
    * @member {String} PhoneNumber
    */
    'PhoneNumber' = undefined;
    /**
    * @member {String} NickName
    */
    'NickName' = undefined;
    /**
    * @member {String} Instructions
    */
    'Instructions' = undefined;
    /**
    * @member {Number} Latitude
    */
    'Latitude' = undefined;
    /**
    * @member {Number} Longitude
    */
    'Longitude' = undefined;




}
