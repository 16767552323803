import { combineReducers } from 'redux';
import { createMigrate, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import moment from 'moment-timezone';

import api from './api';
import auth from './auth';
import catalog from './catalog';
import loyalty from './loyalty';
import cards from './cards';
import favorites from './favorites';
import history from './history';
import location from './location';
import selectedItem from './selectedItem';
import { cart } from './cart';
import content from './content';
import delivery from './delivery';
import curbside from './curbside';
import dineIn from './dineIn';
import inventory from './inventory';

// Root Reducer
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: []
}

const favoritesMigrations = {
  0: (state) => {
    return {
      ...state,
      items: []
    }
  },
  1: (state) => {
    return {
      ...state,
      items: []
    }
  },
  2: (state) => {
    return {
	  ...state,
      remote: []
    }
  },
}

const favoritesPersistConfig = {
  key: 'favorites',
  version: 1,
  storage: storage,
  timeout: null,
  migrate: createMigrate(favoritesMigrations, { debug: false })
}

const locationMigrations = {
  0: (state) => {
    return {
      ...state,
      selectedLocationId: null
  }
  },
  1: (state) => {
    return {
      ...state,
      selectedLocationId: null
    }
  }
}

const locationPersistConfig = {
  key: 'location',
  version: 1,
  storage: storage,
  timeout: null,
  migrate: createMigrate(locationMigrations, { debug: false }),
  whitelist: ['selectedLocationId']
}

const deliveryMigrations = {
  0: (state) => {
    return {
      ...state,
      selectedAddressId: null
  }
  },
  1: (state) => {
    return {
      ...state,
      selectedAddressId: null
    }
  }
}

const deliveryPersistConfig = {
  key: 'delivery',
  version: 1,
  storage: storage,
  timeout: null,
  migrate: createMigrate(deliveryMigrations, { debug: false }),
  whitelist: ['selectedAddressId']  
}

const curbsideMigrations = {
  0: (state) => {
    return {
      ...state,
      vehicleDetails: null,
      ignoredOrders: []
  }
  },
  1: (state) => {
    return {
      ...state,
      vehicleDetails: null,
      ignoredOrders: []
    }
  }
};

const curbsidePersistConfig = {
  key: 'curbside',
  version: 1,
  storage: storage,
  timeout: null,
  migrate: createMigrate(curbsideMigrations, { debug: false }),
  whitelist: ['vehicleDetails', 'ignoredOrders']  
};

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function hasSessionStorage() {
  var storageType = 'sessionStorage';

  var self = global;

  if ((typeof self === 'undefined' ? 'undefined' : _typeof(self)) !== 'object' || !(storageType in self)) {
    return false;
  }

  try {
    var storage = self[storageType];
    var testKey = 'redux-persist ' + storageType + ' test';
    storage.setItem(testKey, 'test');
    storage.getItem(testKey);
    storage.removeItem(testKey);
  } catch (e) {
    return false;
  }

  return true;
}

var catalogReducer = catalog;
var cartReducer = cart;

if (hasSessionStorage()) {
  const cartDateTransform = createTransform(
    (inboundState, key) => {
      var date = inboundState;
      if (date != null) {
        date = date.toISOString();
      }
      return date;
    },
    (outboundState, key) => {
      var date = outboundState;
      if (date != null) {
        date = moment.utc(date);
      }
      return date;
    },
    { whitelist: ['date'] }
  );

  const cartTimeTransform = createTransform(
    (inboundState, key) => {
      var time = inboundState;
      if (time != null) {
        time = {
          ...time,
          value: time.value.toISOString()
        }
      }
      return time;
    },
    (outboundState, key) => {
      var time = outboundState;
      if (time != null) {
        time = {
          ...time,
          value: moment.utc(time.value)
        }
      }
      return time;
    },
    { whitelist: ['time'] }
  );  

  var storageSession = createWebStorage("session");
  
  const catalogPersistConfig = {
    key: 'catalog',
    storage:storageSession,
    timeout: null,
    whitelist: ['selectedCategoryId']
  }
  
  const cartPersistConfig = {
    key: 'cart',
    storage: storageSession,
    whitelist: ['idempotencyKey', 'items', 'coupons', 'rewards','date', 'time', 'userSelectedDateTime'],
    timeout: null,
    transforms: [ cartDateTransform, cartTimeTransform ]
  }

  catalogReducer = persistReducer(catalogPersistConfig, catalog);
  cartReducer = persistReducer(cartPersistConfig, cart);
}

var rootReducer = combineReducers({
  api: api,
  catalog: catalogReducer,
  auth: auth,
  loyalty: loyalty,
  cards: cards,
  favorites: persistReducer(favoritesPersistConfig, favorites),
  history: history,
  location: persistReducer(locationPersistConfig, location), 
  selectedItem: selectedItem,
  cart: cartReducer,
  content: content,
  inventory: inventory,
  delivery: persistReducer(deliveryPersistConfig, delivery),
  curbside: persistReducer(curbsidePersistConfig, curbside),
  dineIn: dineIn
});

export default rootReducer;