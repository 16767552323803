/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VariationInventoryResultModel from './VariationInventoryResultModel';

/**
* The InventoryListResultModel model module.
* @module model/InventoryListResultModel
* @version 1.0.0
*/
export default class InventoryListResultModel {
    /**
    * Constructs a new <code>InventoryListResultModel</code>.
    * @alias module:model/InventoryListResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>InventoryListResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/InventoryListResultModel} obj Optional instance to populate.
    * @return {module:model/InventoryListResultModel} The populated <code>InventoryListResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InventoryListResultModel();
                        
            
            if (data.hasOwnProperty('Inventory')) {
                obj['Inventory'] = ApiClient.convertToType(data['Inventory'], [VariationInventoryResultModel]);
            }
        }
        return obj;
    }

    /**
    * @member {Array.<module:model/VariationInventoryResultModel>} Inventory
    */
    'Inventory' = undefined;




}
