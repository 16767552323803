import { default as Internationalization } from './i18n';

var errorCodeMap = {
    'CVV_FAILURE': 'error_code.cvv_failure',

    'BAD_EXPIRATION': 'error_code.bad_expiration',
    'CARD_EXPIRED': 'error_code.card_expired',
    'EXPIRATION_FAILURE': 'error_code.expiration_failure',    
    'INVALID_EXPIRATION': 'error_code.invalid_expiration',

    'ADDRESS_VERIFICATION_FAILURE': 'error_code.address_verification_failure',
    'INVALID_POSTAL_CODE': 'error_code.invalid_postal_code',    

    'INSUFFICIENT_FUNDS': 'error_code.insufficient_funds',
    'TRANSACTION_LIMIT': 'error_code.insufficient_funds',
    'GENERIC_DECLINE': 'error_code.generic_decline',
    'VALUE_TOO_LOW':'error_code.value_too_low',
    
    'VARIATION_OUT_OF_STOCK': 'error_code.variation_out_of_stock'
};

var errorCodePriority = [
    'VARIATION_OUT_OF_STOCK',
    'CVV_FAILURE', 
    'BAD_EXPIRATION', 'EXPIRATION_FAILURE', 'INVALID_EXPIRATION', 'CARD_EXPIRED', 
    'ADDRESS_VERIFICATION_FAILURE','INVALID_POSTAL_CODE',
    'INSUFFICIENT_FUNDS','TRANSACTION_LIMIT','VALUE_TOO_LOW',
    'GENERIC_DECLINE'
];

function getErrorCode(text) {
    if (!text) {
        return null;
    }

    var parts = text.split(' - ');
    if (parts && parts.length == 2) {
        return parts[1];
    }

    return null;
}

function getErrorCodeMessage(code) {
    var result = errorCodeMap[code];
    if (result) {
        result = Internationalization.strings(result);
        if (result) {
            return result;
        }
    }
    return null;    
}

export default {
    parse: (error, fallback) => {
        if (error && error.response && error.response.body && error.response.body.length) {
            var list = error.response.body;
            var errorCodes = [];
            for(var i=0;i<list.length;i++) {
                var entry = list[i];
                if (entry.Value) {
                    var code = getErrorCode(entry.Value);
                    errorCodes.push(code);
                }
            }

            for(var i=0;i<errorCodePriority.length;i++) {
                var priorityCode = errorCodePriority[i];
                for(var j=0;j<errorCodes.length;j++) {
                    var code = errorCodes[j];
                    if (code == priorityCode) {
                        var result = getErrorCodeMessage(code);
                        if (result) {
                            return result;
                        }
                    }
                }
            }
        }

        return fallback;
    }
};