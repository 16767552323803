/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The DeliveryQuoteResultModel model module.
* @module model/DeliveryQuoteResultModel
* @version 1.0.0
*/
export default class DeliveryQuoteResultModel {
    /**
    * Constructs a new <code>DeliveryQuoteResultModel</code>.
    * @alias module:model/DeliveryQuoteResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>DeliveryQuoteResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DeliveryQuoteResultModel} obj Optional instance to populate.
    * @return {module:model/DeliveryQuoteResultModel} The populated <code>DeliveryQuoteResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeliveryQuoteResultModel();
                        
            
            if (data.hasOwnProperty('Valid')) {
                obj['Valid'] = ApiClient.convertToType(data['Valid'], 'Boolean');
            }
            if (data.hasOwnProperty('DeliveryFee')) {
                obj['DeliveryFee'] = ApiClient.convertToType(data['DeliveryFee'], 'Number');
            }
            if (data.hasOwnProperty('ServiceFee')) {
                obj['ServiceFee'] = ApiClient.convertToType(data['ServiceFee'], 'Number');
            }
            if (data.hasOwnProperty('PlaceAt')) {
                obj['PlaceAt'] = ApiClient.convertToType(data['PlaceAt'], 'Date');
            }
            if (data.hasOwnProperty('LeaveAt')) {
                obj['LeaveAt'] = ApiClient.convertToType(data['LeaveAt'], 'Date');
            }
            if (data.hasOwnProperty('DeliverAt')) {
                obj['DeliverAt'] = ApiClient.convertToType(data['DeliverAt'], 'Date');
            }
            if (data.hasOwnProperty('BufferMinimum')) {
                obj['BufferMinimum'] = ApiClient.convertToType(data['BufferMinimum'], 'Number');
            }
            if (data.hasOwnProperty('BufferMaximum')) {
                obj['BufferMaximum'] = ApiClient.convertToType(data['BufferMaximum'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} Valid
    */
    'Valid' = undefined;
    /**
    * @member {Number} DeliveryFee
    */
    'DeliveryFee' = undefined;
    /**
    * @member {Number} ServiceFee
    */
    'ServiceFee' = undefined;
    /**
    * @member {Date} PlaceAt
    */
    'PlaceAt' = undefined;
    /**
    * @member {Date} LeaveAt
    */
    'LeaveAt' = undefined;
    /**
    * @member {Date} DeliverAt
    */
    'DeliverAt' = undefined;
    /**
    * @member {Number} BufferMinimum
    */
    'BufferMinimum' = undefined;
    /**
    * @member {Number} BufferMaximum
    */
    'BufferMaximum' = undefined;




}
