/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LoyaltyTerminologyModel from './LoyaltyTerminologyModel';

/**
* The LoyaltyCheckAvailabilityResultModel model module.
* @module model/LoyaltyCheckAvailabilityResultModel
* @version 1.0.0
*/
export default class LoyaltyCheckAvailabilityResultModel {
    /**
    * Constructs a new <code>LoyaltyCheckAvailabilityResultModel</code>.
    * @alias module:model/LoyaltyCheckAvailabilityResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LoyaltyCheckAvailabilityResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoyaltyCheckAvailabilityResultModel} obj Optional instance to populate.
    * @return {module:model/LoyaltyCheckAvailabilityResultModel} The populated <code>LoyaltyCheckAvailabilityResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoyaltyCheckAvailabilityResultModel();
                        
            
            if (data.hasOwnProperty('Available')) {
                obj['Available'] = ApiClient.convertToType(data['Available'], 'Boolean');
            }
            if (data.hasOwnProperty('Terminology')) {
                obj['Terminology'] = LoyaltyTerminologyModel.constructFromObject(data['Terminology']);
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} Available
    */
    'Available' = undefined;
    /**
    * @member {module:model/LoyaltyTerminologyModel} Terminology
    */
    'Terminology' = undefined;




}
