/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import LoyaltyCheckAvailabilityResultModel from '../model/LoyaltyCheckAvailabilityResultModel';
import LoyaltyEnrollBindingModel from '../model/LoyaltyEnrollBindingModel';
import LoyaltyEnrollResultModel from '../model/LoyaltyEnrollResultModel';
import LoyaltyStatusBindingModel from '../model/LoyaltyStatusBindingModel';
import LoyaltyStatusResultModel from '../model/LoyaltyStatusResultModel';
import LoyaltyVerifyBindingModel from '../model/LoyaltyVerifyBindingModel';
import LoyaltyVerifyResultModel from '../model/LoyaltyVerifyResultModel';
import ModelStateError from '../model/ModelStateError';
import ResendEnrollCodeBindingModel from '../model/ResendEnrollCodeBindingModel';

/**
* Loyalty service.
* @module api/LoyaltyApi
* @version 1.0.0
*/
export default class LoyaltyApi {

    /**
    * Constructs a new LoyaltyApi. 
    * @alias module:api/LoyaltyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the loyaltyCheckAvailability operation.
     * @callback module:api/LoyaltyApi~loyaltyCheckAvailabilityCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoyaltyCheckAvailabilityResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/LoyaltyApi~loyaltyCheckAvailabilityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoyaltyCheckAvailabilityResultModel}
     */
    loyaltyCheckAvailability(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LoyaltyCheckAvailabilityResultModel;

      return this.apiClient.callApi(
        '/api/Loyalty/CheckAvailability', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the loyaltyEnroll operation.
     * @callback module:api/LoyaltyApi~loyaltyEnrollCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoyaltyEnrollResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/LoyaltyApi~loyaltyEnrollCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoyaltyEnrollResultModel}
     */
    loyaltyEnroll(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LoyaltyEnrollResultModel;

      return this.apiClient.callApi(
        '/api/Loyalty/Enroll', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the loyaltyResendEnrollCode operation.
     * @callback module:api/LoyaltyApi~loyaltyResendEnrollCodeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/LoyaltyApi~loyaltyResendEnrollCodeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    loyaltyResendEnrollCode(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Loyalty/ResendEnrollCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the loyaltyStatus operation.
     * @callback module:api/LoyaltyApi~loyaltyStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoyaltyStatusResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/LoyaltyApi~loyaltyStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoyaltyStatusResultModel}
     */
    loyaltyStatus(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LoyaltyStatusResultModel;

      return this.apiClient.callApi(
        '/api/Loyalty/Status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the loyaltyVerify operation.
     * @callback module:api/LoyaltyApi~loyaltyVerifyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoyaltyVerifyResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/LoyaltyApi~loyaltyVerifyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoyaltyVerifyResultModel}
     */
    loyaltyVerify(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LoyaltyVerifyResultModel;

      return this.apiClient.callApi(
        '/api/Loyalty/Verify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
