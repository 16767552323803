import moment from 'moment';

function handleAction(action) {
    if (!global || !global.oneSignal || !global.oneSignal.enabled || !global.oneSignal.enabled()) {
        return;
    }
    if (!action || !action.type) {
        return;
    }

    switch(action.type) {
        case 'AUTH_STATUS':
            if (action.data.CustomerSince) {
                var timestamp = Math.floor(moment(action.data.CustomerSince).unix());
                if (timestamp) {
                    global.oneSignal.addTrigger('customer_since', timestamp);
                }
            }
            break;
    }
}

export const oneSignalIntegration = store => next => action => {
    handleAction(action);
    return next(action);
};
