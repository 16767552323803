/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LoyaltyCouponModel from './LoyaltyCouponModel';
import LoyaltyRewardModel2 from './LoyaltyRewardModel2';
import LoyaltyTerminologyModel from './LoyaltyTerminologyModel';

/**
* The LoyaltyStatusResultModel model module.
* @module model/LoyaltyStatusResultModel
* @version 1.0.0
*/
export default class LoyaltyStatusResultModel {
    /**
    * Constructs a new <code>LoyaltyStatusResultModel</code>.
    * @alias module:model/LoyaltyStatusResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LoyaltyStatusResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoyaltyStatusResultModel} obj Optional instance to populate.
    * @return {module:model/LoyaltyStatusResultModel} The populated <code>LoyaltyStatusResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoyaltyStatusResultModel();
                        
            
            if (data.hasOwnProperty('Available')) {
                obj['Available'] = ApiClient.convertToType(data['Available'], 'Boolean');
            }
            if (data.hasOwnProperty('Terminology')) {
                obj['Terminology'] = LoyaltyTerminologyModel.constructFromObject(data['Terminology']);
            }
            if (data.hasOwnProperty('Enrolled')) {
                obj['Enrolled'] = ApiClient.convertToType(data['Enrolled'], 'Boolean');
            }
            if (data.hasOwnProperty('Balance')) {
                obj['Balance'] = ApiClient.convertToType(data['Balance'], 'Number');
            }
            if (data.hasOwnProperty('Rewards')) {
                obj['Rewards'] = ApiClient.convertToType(data['Rewards'], [LoyaltyRewardModel2]);
            }
            if (data.hasOwnProperty('Coupons')) {
                obj['Coupons'] = ApiClient.convertToType(data['Coupons'], [LoyaltyCouponModel]);
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} Available
    */
    'Available' = undefined;
    /**
    * @member {module:model/LoyaltyTerminologyModel} Terminology
    */
    'Terminology' = undefined;
    /**
    * @member {Boolean} Enrolled
    */
    'Enrolled' = undefined;
    /**
    * @member {Number} Balance
    */
    'Balance' = undefined;
    /**
    * @member {Array.<module:model/LoyaltyRewardModel2>} Rewards
    */
    'Rewards' = undefined;
    /**
    * @member {Array.<module:model/LoyaltyCouponModel>} Coupons
    */
    'Coupons' = undefined;




}
