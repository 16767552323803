import { default as ApiService } from './apiService';

export default class ShoppingCartService {

    _createPreviewModel(idempotencyKey, items, coupons, rewards, location) {

        var lineItems = [];
        items.forEach((entry) => {
            var lineItem = {
                key: entry.key,
                id: entry.variation,
                quantity: entry.quantity,
                note: entry.note,
                modifiers: [].concat.apply([],Object.values(entry.modifiers))
            };
            lineItems.push(lineItem);
        });        

        var model = {
            idempotencyKey: idempotencyKey,
            locationId: location.Id,
            lineItems: lineItems,
        };

        if (rewards && rewards.length > 0) {
            model.rewardId = rewards[0].Id;
        } else if(coupons && coupons.length > 0) {
            model.couponId = coupons[0].Id;
        }

        return model;
    }

    _createModel(idempotencyKey, card, items, coupons, rewards, tipAmount, location, source, time, guest, fulfillmentType, fulfillmentSettings) {
        var cardModel = {
            id: card.id,
            nonce: card.nonce,
            address1: card.address.address1,
            address2: card.address.address2,
            city: card.address.city,
            state: card.address.state,
            postalCode: card.address.postalCode
        };

        var lineItems = [];
        items.forEach((entry) => {
            var lineItem = {
                key: entry.key,
                id: entry.variation,
                quantity: entry.quantity,
                note: entry.note,
                modifiers: [].concat.apply([],Object.values(entry.modifiers))
            };
            lineItems.push(lineItem);
        });

        var pickupUtc = null;
        if (!time.isASAP) {
            pickupUtc = time.value.clone().tz('Etc/UTC').format();
        }

        if (typeof source !== 'string') {
            source = null;
        }

        var dineInFulfillment = null;
        var pickupFulfillment = null;
        var curbsideFulfillment = null;
        var deliveryFulfillment = null;
        if (fulfillmentType == 'DINEIN') {
            dineInFulfillment = {
                pickupAt: pickupUtc,
                notes: fulfillmentSettings.note
            };
        } else if (fulfillmentType == 'PICKUP') {
            pickupFulfillment = {
                pickupAt: pickupUtc,
                notes: fulfillmentSettings.notes
            };
        } else if (fulfillmentType == 'CURBSIDE') {
            var vehicleDetails = fulfillmentSettings.vehicleDetails || {};
            curbsideFulfillment = {
                pickupAt: pickupUtc,
                vehicleDetails: {
                    make: vehicleDetails.make,
                    model: vehicleDetails.model,
                    color: vehicleDetails.color,
                    note: vehicleDetails.note
                }
            };
        } else if (fulfillmentType == 'DELIVERY') {
            deliveryFulfillment = {
                deliverAt: null, // TODO
                deliveryAddressId: fulfillmentSettings.deliveryAddressId
            };
        } else {
            pickupFulfillment = {
                pickupAt: pickupUtc
            };
        }

        var model = {
            source: source,
            idempotencyKey: idempotencyKey,
            locationId: location.Id,
            card: cardModel,
            lineItems: lineItems,
            pickupAt: pickupUtc,
            tipAmount: tipAmount,
            dineInFulfillment: dineInFulfillment,
            pickupFulfillment: pickupFulfillment,
            curbsideFulfillment: curbsideFulfillment,
            deliveryFulfillment: deliveryFulfillment
        };

        if (rewards && rewards.length > 0) {
            model.rewardId = rewards[0].Id;
        } else if(coupons && coupons.length > 0) {
            model.couponId = coupons[0].Id;
        }

        if (guest) {
            model.guest = {
                firstName: guest.firstName,
                lastName: guest.lastName,
                emailAddress: guest.email,
                phoneNumber: guest.phone
            };
        }

        return model;
    }

    queueOrder(idempotencyKey, card, items, coupons, tipAmount, location, source, time, guest, callback) {
        var context = {
            idempotencyKey: idempotencyKey,
            card: card,
            items: items,
            coupons: coupons,
            rewards: [],
            tipAmount: tipAmount,
            location: location,
            source: source,
            time: time,
            guest: guest
        };

        this.queueContextOrder(context, callback);
    }

    queueContextOrder(context, callback) {
        var model = this._createModel(
            context.idempotencyKey, 
            context.card, 
            context.items, 
            context.coupons, 
            context.rewards, 
            context.tipAmount, 
            context.location, 
            context.source, 
            context.time, 
            context.guest
        );

        if (model.guest) {
            ApiService.instance.orderGuestQueue(model, callback);
        } else {
            ApiService.instance.orderQueue(model, callback);
        }        
    }

    placeOrder(idempotencyKey, card, items, coupons, tipAmount, location, source, time, guest, callback) {
        var context = {
            idempotencyKey: idempotencyKey,
            card: card,
            items: items,
            coupons: coupons,
            rewards: [],
            tipAmount: tipAmount,
            location: location,
            source: source,
            time: time,
            guest: guest
        };

        this.placeContextOrder(context, callback);
    }

    placeContextOrder(context, callback) {
        var model = this._createModel(
            context.idempotencyKey, 
            context.card, 
            context.items, 
            context.coupons, 
            context.rewards, 
            context.tipAmount, 
            context.location, 
            context.source, 
            context.time, 
            context.guest,
            context.fulfillmentType,
            context.fulfillmentSettings
        );

        if (model.guest) {
            ApiService.instance.orderGuestPlace(model, callback);
        } else {
            ApiService.instance.orderPlace(model, callback);
        }        
    }
    previewContextOrder(context, callback) {
        var model = this._createPreviewModel(
            context.idempotencyKey, 
            context.items, 
            context.coupons, 
            context.rewards, 
            context.location
        );

        debugger;

        if (model.guest) {
            // ApiService.instance.orderGuestPlace(model, callback);
        } else {
            //ApiService.instance.orderPlace(model, callback);
        }        
    }    
}

ShoppingCartService.instance = new ShoppingCartService();