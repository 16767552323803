import React from 'react';
import { connect } from 'react-redux';
import { Hero, FavoriteIcon } from '../components';
import { Internationalization, CurrencyFormatter, CustomStorage, InventoryHelper } from '../lib';
import { default as GeoHelper } from '../geoHelper';
import { RewardHelper } from '../services';

class CartScreen extends React.Component {
    getSortedItemModifierLists(item) {
        var map = this.props.modifierListMap;
        return item.ModifierLists.sort((a, b) => {
            var listA = map.get(a.Id);
            var listB = map.get(b.Id);

            var nameA = listA.Name.toUpperCase();
            var nameB = listB.Name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    renderCartEntry(entry) {
        
        console.log('cart entry: ', entry);

        var id = entry.item;
        
        var item = this.props.itemsById[id];

        var imageSource = null;
        
        if (!item.ImageUrl) {
            imageSource = global.imagesPath + 'item-placeholder.jpg';
        } else {
            imageSource = item.ImageUrl;
        }        

        var notes = null;
        if (entry.note) {
            notes = (<div className="notes"><strong>Notes:</strong> {entry.note}</div>);
        }

        var price = 0;
        var lineItem = this.props.preview.lineItems[entry.key];
        if (lineItem) {
            price = lineItem.grossSales;
        }

        var modifierListMap = this.props.modifierListMap;
        var modifierMap = this.props.modifierMap;

        var variationId = entry.variation;
        var variation = item.Variations.find((x) => x.Id == variationId );

        var modifierLines = [];
        var itemModifierLists = this.getSortedItemModifierLists(item);
        for(var i=0;i<itemModifierLists.length;i++) {
            var itemModifierList = itemModifierLists[i];
            var selectedModifierIds = entry.modifiers[itemModifierList.Id];
            if (!selectedModifierIds || selectedModifierIds.length == 0) {
                continue;
            }
            
            
            var modifierNames = [];
            for(var j=0;j<selectedModifierIds.length;j++) {
                var modifierId = selectedModifierIds[j];
                var modifier = modifierMap[modifierId];
                if (!modifier) {
                    continue;
                }
                modifierNames.push(modifier.Name);
            }
            
            if (modifierNames.length > 0) {
                var modifierList = modifierListMap.get(itemModifierList.Id);
                modifierLines.push(<span key={itemModifierList.Id}>{modifierList.Name + ': ' + modifierNames.join(', ')}<br/></span>);
            }
        }        
        
        return (
            <div key={entry.key} className="row">
                <div className="pic"><img src={imageSource} alt={item.Name} /></div>
                <div className="title-info">
                    <h3><a onClick={() => this.onEditClicked(entry)} href="javascript://">{item.Name}</a></h3>
                    <div className="description">
                        <span>{variation.Name}<br /></span>
                        {modifierLines}
                    </div>
                    {notes}
                </div>
                <div className="qty">
                    <a href="javascript://" onClick={() => this.onMinusClicked(entry)} className="subtract"><i className="material-icons md-24">remove</i></a>
                    <div className="qty-num">{entry.quantity}</div>
                    <a href="javascript://" onClick={() => this.onPlusClicked(entry)} className="add"><i className="material-icons md-24">add</i></a>
                </div>
                <div className="price">
                    {CurrencyFormatter.format(price, this.props.currencyCode)}
                </div>
                <div className="edit">
                    <a onClick={() => this.onEditClicked(entry)} href="javascript://"><i className="fas fa-pencil-alt fa-2x"></i></a>
                </div>                
                <div className="remove">
                    <a onClick={() => this.onRemoveClicked(entry)} href="javascript://"><i className="fas fa-times fa-2x"></i></a>
                </div>
            </div>
        );
    }

    getRewardNote(coupon) {
        var percentage = coupon.Percentage;
        var maximumMoney = coupon.MaximumMoney;
        var amountMoney = coupon.AmountMoney;
        var type =RewardHelper.typeConverter(coupon.Type) ;
        var categories = coupon.Categories || [];
        var items = coupon.Items || [];
        var currencyCode = this.props.currencyCode;

        var key = null;
        var model = {};

        
        if (percentage)
        {
            key = 'percentage_off';
            model.percentage = percentage;
            if (maximumMoney)
            {
                key += '_with_max';
                model.maximum = CurrencyFormatter.format(maximumMoney, currencyCode);
            }
        }
        else if (amountMoney)
        {
            key = 'amount_off';
            model.amount = CurrencyFormatter.format(amountMoney, currencyCode);
        }
        else
        {
            return null;
        }

        if (type == 'EntireOrder')
        {
            key += '_entire_order';
        }
        else if (type == 'Categories')
        {
            key += '_by_categories';
            model.count = categories.length;
        }
        else
        {
            key += '_by_items';
            model.count = items.length+coupon.Variations.length;
        }

        return Internationalization.strings('coupon_notes.' + key, model);
    } 

    renderCouponEntry(coupon) {

        var name = coupon.Name;
        var description = coupon.Description;

        var note = this.getRewardNote(coupon);

        var imageClasses = ['fas','fa-5x', 'fa-fw'];
        if (coupon.Percentage) {
            imageClasses.push('fa-percent');
        } else {
            imageClasses.push('fa-tags');
        }

        var image = (<i className={imageClasses.join(' ')}></i>);

        return (
            <div key={coupon.Id} className="row coupon">
                <div className="pic">{image}</div>
                <div className="title-info">
                    <h3>{name}</h3>
                    <div className="description">{description}</div>
                    <div className="notes">{note}</div>
                </div>
                <div className="remove">
                    <a onClick={() => this.onRemoveCoupon(coupon)} href="javascript://"><i className="fas fa-times fa-2x"></i></a>
                </div>
            </div>
        );
    }
    
    renderRewardEntry(reward) {

        var name = reward.Name;
        var description = reward.Description;

        var note = this.getRewardNote(reward);

        var imageClasses = ['fas','fa-5x', 'fa-fw'];
        if (reward.Percentage) {
            imageClasses.push('fa-percent');
        } else {
            imageClasses.push('fa-tags');
        }

        var image = (<i className={imageClasses.join(' ')}></i>);

        return (
            <div key={reward.Id} className="row coupon">
                <div className="pic">{image}</div>
                <div className="title-info">
                    <h3>{name}</h3>
                    <div className="description">{description}</div>
                    <div className="notes">{note}</div>
                </div>
                <div className="remove">
                    <a onClick={() => this.onRemoveReward(reward)} href="javascript://"><i className="fas fa-times fa-2x"></i></a>
                </div>
            </div>
        );
    }
    isRewardActive(reward) {
        var discounts = this.props.preview.totalDiscounts;
        var isActive = discounts < 0;
        return isActive;
    }
    getRewardDescription(reward) {
        var result = RewardHelper.getRewardTranslationModel(reward, this.props.currencyCode, this.props.categoriesById, this.props.variationsById, this.props.itemsById);
        return Internationalization.strings('reward_notes.' + result.key, result.model);
    }
    // getRewardNote(reward) {
    //     var model = {
    //         count: reward.Points,
    //         singular: this.props.terminology.one,
    //         plural: this.props.terminology.other
    //     };

    //     return Internationalization.strings('cart_screen.reward_cost', model);
    // }

    

    onRemoveClicked(entry) {
        this.props.remove(entry);
    }

    onEditClicked(entry) {
        this.props.select(entry);
    }

    onPlusClicked(entry) {
        var summary = InventoryHelper.getInventorySummary(entry.variation, this.props.variationsById, this.props.inventoryCounts, this.props.items);
        if (summary.remaining <= 0) {
           this.props.toast.warn(Internationalization.strings('cart_screen.available_quantity_limited_warning', { count: summary.stockCount } ));
           return;
        }

        this.props.increase(entry);
    }

    onMinusClicked(entry) {
       this.props.decrease(entry);
    }

    onRemoveCoupon(coupon) {
        this.props.removeCoupon(coupon);
    }
    onRemoveReward(reward) {
        this.props.removeReward(reward);
    }
    formatAddress() {
        var address = this.props.location.Address;

        var parts = [
            address.AddressLine1
        ];

        if (address.AddressLine2 != '') {
            parts.push(address.AddressLine2);
        }
        parts.push(address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode);

        return parts.join(' ');
    }    

    onSignInClicked() {
        CustomStorage.set('returnUrl', '/checkout');
        this.props.history.push('/sign-in');
    }

    onGuestCheckoutClicked() {
        this.props.history.push('/checkout');
    }

    onCheckoutClicked() {
        this.props.history.push('/checkout');        
    }

    renderBagContents() {
        if (this.props.items.length + this.props.coupons.length + this.props.rewards.length == 0) {
            return null;
        }

        var subTotal = this.props.preview.grossTotal;
        var totalTaxes = this.props.preview.totalTaxes;
        var totalDiscounts = this.props.preview.totalDiscounts;
        var grandTotal = this.props.preview.grandTotal;

        var discountContainer = null;
        if (totalDiscounts < 0) {
            discountContainer = (
                <div className="discount">
                    <div className="txt">{Internationalization.strings('cart_screen.discount')}</div>
                    <div className="price">{CurrencyFormatter.format(totalDiscounts, this.props.currencyCode)}</div>
                    <div className="empty"></div>
                </div>
            );
        }

        var itemRows = [];
        for(var i=0;i<this.props.items.length;i++) {
            var entry = this.props.items[i];
            var itemRow = this.renderCartEntry(entry);
            itemRows.push(itemRow);
        }

        var couponRows = [];
        for(var i=0;i<this.props.coupons.length;i++) {
            var coupon = this.props.coupons[i];
            var couponRow = this.renderCouponEntry(coupon);
            couponRows.push(couponRow);
        }

        var rewardRows = [];
        for(var i=0;i<this.props.rewards.length;i++) {
            var reward = this.props.rewards[i];
            var rewardRow = this.renderRewardEntry(reward);
            rewardRows.push(rewardRow);
        }
        return (
            <div className="bag-full-of-stuff">
                {itemRows}
                {couponRows}                        
                {rewardRows}
                <div className="subtotal">
                    <div className="txt">{Internationalization.strings('cart_screen.sub_total')}</div>
                    <div className="price">{CurrencyFormatter.format(subTotal, this.props.currencyCode)}</div>
                    <div className="empty"></div>
                </div>
                <div className="tax">
                    <div className="txt">{Internationalization.strings('cart_screen.tax')}</div>
                    <div className="price">{CurrencyFormatter.format(totalTaxes, this.props.currencyCode)}</div>
                    <div className="empty"></div>
                </div>
                {discountContainer}
                <div className="total">
                    <div className="txt">{Internationalization.strings('cart_screen.total')}</div>
                    <div className="price">{CurrencyFormatter.format(grandTotal, this.props.currencyCode)}</div>
                    <div className="empty"></div>
                </div>
            </div>
        );
    }

    renderCheckoutActions() {
        if (this.props.items.length + this.props.coupons.length == 0) {
            return null;
        }
        if (this.props.isLoggedIn) {
            return (
                <div className="checkout-ctas">
                    <a href="javascript://" onClick={() => this.onCheckoutClicked()} className="btn btn-primary"><span>Checkout</span></a>
                </div>
            );
        } else {
            return (
                <div className="checkout-ctas">
                    <a href="javascript://" onClick={() => this.onSignInClicked()} className="btn btn-primary"><span>Sign In / Register</span></a>
                    <span>or</span>
                    <a href="javascript://" onClick={() => this.onGuestCheckoutClicked()} className="btn btn-primary"><span>Checkout as Guest</span></a>
                </div>
            );
        }
    }

    onLocationClicked() {
        GeoHelper.instance.watch();
        this.props.openLocationModal();
    }

    render() {
        var changeLocationAction = null;
        if (this.props.hasMultipleLocations) {
            changeLocationAction = (<a href="javascript://" onClick={()=>this.onLocationClicked()}>Change location</a>);
        }

        return (
            <div className="bag">
                <Hero message="Cart" small />
                <div className="body">
                    <div className="container">
                        <div className="intro">
                            <p>Your order will be at this location: <strong>{this.formatAddress()}</strong> {changeLocationAction}</p>
                            {this.props.items.length + this.props.coupons.length + this.props.rewards.length == 0 && <h2>Your bag is currently empty.</h2>}
                        </div>
                        {this.renderBagContents()}
                        {this.renderCheckoutActions()}
                    </div>
                </div>     
            </div>   
        );
    }
}

const mapStateToProps = (state) => {
    var inventoryCountsState = state.inventory.locationVariationMap[state.location.selectedLocationId] || {};
    var inventoryCounts = { ...inventoryCountsState  };

    return {
        modifierListMap: state.selectedItem.modifierListMap,
        modifierMap: state.selectedItem.modifierMap,
        itemsById: state.catalog.itemsById,
        preview: state.cart.preview,
        items: state.cart.items,
        location: state.cart.location,
        currencyCode: state.location.currentLocation.Currency,
        isLoggedIn: state.auth.isLoggedIn,
        hasMultipleLocations: state.location.locations.length > 1,
        coupons: state.cart.coupons,    
        rewards: state.cart.rewards,
        terminology: state.loyalty.terminology,

        inventoryCounts: inventoryCounts,
        variationsById: state.catalog.variationsById
    }
};

const mapDispatchToProps = (dispatch) => ({
    increase: (cartItem) => {
        dispatch({type:'CART_QUANTITY_INCREASE', cartItem: cartItem});
    },
    decrease: (cartItem) => {
        dispatch({type:'CART_QUANTITY_DECREASE', cartItem: cartItem});
    },
    remove: (cartItem) => {
        cartItem.quantity = 0;
        dispatch({type:'CART_UPDATE', cartItem: cartItem});
    },    
    removeCoupon: (coupon) => {
        dispatch({type: 'CART_COUPON_REMOVE', coupon: coupon});
    },
    removeReward: (reward) => {
        dispatch({type: 'CART_REWARD_REMOVE', reward: reward});
    },
    select: (cartItem) => {
        dispatch({type: 'CATALOG_SELECT_CART_ITEM', cartItem: cartItem});
    },
    openLocationModal: () => {
        dispatch({type: 'LOCATION_CHOOSER'});
    }    
});

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);