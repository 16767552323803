/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ItemModifier from './ItemModifier';

/**
* The ItemModifierList model module.
* @module model/ItemModifierList
* @version 1.0.0
*/
export default class ItemModifierList {
    /**
    * Constructs a new <code>ItemModifierList</code>.
    * @alias module:model/ItemModifierList
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ItemModifierList</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ItemModifierList} obj Optional instance to populate.
    * @return {module:model/ItemModifierList} The populated <code>ItemModifierList</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ItemModifierList();
                        
            
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('MinSelectedModifiers')) {
                obj['MinSelectedModifiers'] = ApiClient.convertToType(data['MinSelectedModifiers'], 'Number');
            }
            if (data.hasOwnProperty('MaxSelectedModifiers')) {
                obj['MaxSelectedModifiers'] = ApiClient.convertToType(data['MaxSelectedModifiers'], 'Number');
            }
            if (data.hasOwnProperty('Modifiers')) {
                obj['Modifiers'] = ApiClient.convertToType(data['Modifiers'], [ItemModifier]);
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    'Id' = undefined;
    /**
    * @member {Number} MinSelectedModifiers
    */
    'MinSelectedModifiers' = undefined;
    /**
    * @member {Number} MaxSelectedModifiers
    */
    'MaxSelectedModifiers' = undefined;
    /**
    * @member {Array.<module:model/ItemModifier>} Modifiers
    */
    'Modifiers' = undefined;




}
