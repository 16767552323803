/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The StatusResultModel model module.
* @module model/StatusResultModel
* @version 1.0.0
*/
export default class StatusResultModel {
    /**
    * Constructs a new <code>StatusResultModel</code>.
    * @alias module:model/StatusResultModel
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>StatusResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/StatusResultModel} obj Optional instance to populate.
    * @return {module:model/StatusResultModel} The populated <code>StatusResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StatusResultModel();
                        
            
            if (data.hasOwnProperty('Verified')) {
                obj['Verified'] = ApiClient.convertToType(data['Verified'], 'Boolean');
            }
            if (data.hasOwnProperty('TextNotificationOptIn')) {
                obj['TextNotificationOptIn'] = ApiClient.convertToType(data['TextNotificationOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('CustomerSince')) {
                obj['CustomerSince'] = ApiClient.convertToType(data['CustomerSince'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} Verified
    */
    'Verified' = undefined;
    /**
    * @member {Boolean} TextNotificationOptIn
    */
    'TextNotificationOptIn' = undefined;
    /**
    * @member {Date} CustomerSince
    */
    'CustomerSince' = undefined;




}
