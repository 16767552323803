/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ListCardBindingModel from '../model/ListCardBindingModel';
import ListCardResultModel from '../model/ListCardResultModel';
import MergeCardBindingModel from '../model/MergeCardBindingModel';
import ModelStateError from '../model/ModelStateError';
import RemoveCardBindingModel from '../model/RemoveCardBindingModel';
import SaveCardBindingModel from '../model/SaveCardBindingModel';
import SaveCardResultModel from '../model/SaveCardResultModel';

/**
* Card service.
* @module api/CardApi
* @version 1.0.0
*/
export default class CardApi {

    /**
    * Constructs a new CardApi. 
    * @alias module:api/CardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the cardCreate operation.
     * @callback module:api/CardApi~cardCreateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SaveCardResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CardApi~cardCreateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SaveCardResultModel}
     */
    cardCreate(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SaveCardResultModel;

      return this.apiClient.callApi(
        '/api/Card/Create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cardList operation.
     * @callback module:api/CardApi~cardListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ListCardResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CardApi~cardListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ListCardResultModel}
     */
    cardList(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ListCardResultModel;

      return this.apiClient.callApi(
        '/api/Card/List', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cardMerge operation.
     * @callback module:api/CardApi~cardMergeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ListCardResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CardApi~cardMergeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ListCardResultModel}
     */
    cardMerge(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ListCardResultModel;

      return this.apiClient.callApi(
        '/api/Card/Merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the cardRemove operation.
     * @callback module:api/CardApi~cardRemoveCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/CardApi~cardRemoveCallback} callback The callback function, accepting three arguments: error, data, response
     */
    cardRemove(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Card/Remove', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
