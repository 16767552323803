/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The DeliverySettings model module.
* @module model/DeliverySettings
* @version 1.0.0
*/
export default class DeliverySettings {
    /**
    * Constructs a new <code>DeliverySettings</code>.
    * @alias module:model/DeliverySettings
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>DeliverySettings</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/DeliverySettings} obj Optional instance to populate.
    * @return {module:model/DeliverySettings} The populated <code>DeliverySettings</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeliverySettings();
                        
            
            if (data.hasOwnProperty('Enabled')) {
                obj['Enabled'] = ApiClient.convertToType(data['Enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('Radius')) {
                obj['Radius'] = ApiClient.convertToType(data['Radius'], 'Number');
            }
            if (data.hasOwnProperty('CheckoutMessage')) {
                obj['CheckoutMessage'] = ApiClient.convertToType(data['CheckoutMessage'], 'String');
            }
            if (data.hasOwnProperty('ConfirmationMessage')) {
                obj['ConfirmationMessage'] = ApiClient.convertToType(data['ConfirmationMessage'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} Enabled
    */
    'Enabled' = undefined;
    /**
    * @member {Number} Radius
    */
    'Radius' = undefined;
    /**
    * @member {String} CheckoutMessage
    */
    'CheckoutMessage' = undefined;
    /**
    * @member {String} ConfirmationMessage
    */
    'ConfirmationMessage' = undefined;




}
